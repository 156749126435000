import { v4 } from 'uuid'
import { ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/firebase'
import { useUserCompanyStore, useUserStore } from '@/users/stores'
import { authenticateUserWithLoginForm, hasValidToken } from '@/google'

export const useGoogleDriveStore = defineStore('googleDrive', () => {
  const driveFolder = ref(null)
  const driveFiles = ref([])
  const folderCrumbs = ref([])
  const driveLoaded = ref(false)
  const pending = ref(false)

  function setDriveFolder(value) {
    const companyStore = useUserCompanyStore()
    const folder = { ...value }
    if (value.name === 'Drive') folder.name = companyStore.company.name
    driveFolder.value = folder
    folderCrumbs.value = [folder]
  }

  function gotoDriveFolder(value) {
    const index = folderCrumbs.value.findIndex((folder) => folder.id === value.id)
    if (index < 0) folderCrumbs.value.push(value)
    else folderCrumbs.value = folderCrumbs.value.slice(0, index + 1)
  }

  function updateByIndex(value, i) {
    const index = driveFiles.value.findIndex((file) => file.index === i)
    if (index >= 0) driveFiles.value[index] = value
  }

  function updateFile(value) {
    const index = value.value ? driveFiles.value.findIndex((file) => file.value === value.value)
      : driveFiles.value.findIndex((file) => file.id === value.id)
    if (index < 0) console.log('failed to find file to update', value)
    else driveFiles.value[index] = value
  }

  function addFile(value) {
    driveFiles.value.splice(0, 0, value)
  }

  async function checkGDriveScope() {
    const { user } = useUserStore()
    if (!user.google || !hasValidToken(user.google, 'https://www.googleapis.com/auth/drive')) {
      await authenticateUserWithLoginForm(user, 'https://www.googleapis.com/auth/drive')
    }
  }

  async function initDriveFiles(folderId) {
    try {
      const response = await httpsCallable(functions, 'gdrive-listDriveFiles')({ driveId: folderId })
      driveFiles.value = response.data
      driveLoaded.value = true
    } catch (error) {
      console.error('listDriveFiles failed.', {
        error,
      })
    }
  }

  async function initDriveFolder(folderId) {
    if (driveLoaded.value) {
      setDriveFolder(folderCrumbs.value[0])
      return
    }
    try {
      const response = await httpsCallable(functions, 'gdrive-getDriveFileMetadata')({ fileId: folderId })
      setDriveFolder(response.data)
      await initDriveFiles(folderId)
    } catch (error) {
      console.error('getDriveFileMetadata failed.', {
        error,
      })
    }
  }

  async function createNewFolder(args) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    const index = driveFiles.value.length
    const file = {
      name: args.foldername,
      parents: [args.parentId],
      trashed: false,
      properties: { owner: args.owner },
      mimeType: 'application/vnd.google-apps.folder',
      iconLink: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.folder+48',
      modifiedTime: Date.now(),
      index: driveFiles.value.length,
    }
    addFile(file)
    const response = await httpsCallable(functions, 'gdrive-createDriveFolder')({
      name: args.foldername,
      parentId: args.parentId,
      owner: args.owner,
    })
    const filedata = response.data
    updateByIndex(filedata, index)
    pending.value = false
  }

  async function uploadFile(args) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    const index = driveFiles.value.length
    const file = {
      name: args.filename,
      parents: [args.parentId],
      trashed: false,
      properties: { owner: args.owner },
      modifiedTime: Date.now(),
      mimeType: '',
      index,
    }
    addFile(file)
    try {
      const response = await httpsCallable(functions, 'gdrive-uploadDriveFile')({
        filename: args.filename,
        type: args.filetype,
        parentId: args.parentId,
        owner: args.owner,
      })
      const filedata = response.data
      updateByIndex(filedata, index)
      pending.value = false
      return filedata
    } catch (error) {
      console.error('uploadFile failed', {
        error,
      })
      pending.value = false
      return null
    }
  }

  async function renameFile(file) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    updateFile(file)
    const owner = (file.properties && file.properties.owner)
      ? file.properties.owner
      : file.lastModifyingUser.displayName

    try {
      await httpsCallable(functions, 'gdrive-renameDriveFile')({
        filename: file.name,
        fileId: file.id,
        owner,
      })
    } catch (error) {
      console.error('renameDriveFile failed.', {
        error,
      })
    } finally {
      pending.value = false
    }
  }

  async function shareFile(who, file) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    const permissions = { fileId: file.id }
    if (who.includes('@')) {
      permissions.email = who
    } else if (who !== 'anyone') { permissions.domain = who }

    try {
      await httpsCallable(functions, 'gdrive-shareDriveFile')(permissions)
    } catch (error) {
      console.error('shareFile failed.', {
        error,
      })
    } finally {
      pending.value = false
    }
  }

  async function moveDriveFiles(args) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    let ok = true
    /* eslint-disable no-restricted-syntax, no-await-in-loop */
    for (const file of args.files) {
      file.parents = [args.newParentId]
      updateFile(file)
    }
    for (const file of args.files) {
      try {
        await httpsCallable(functions, 'gdrive-moveDriveFile')({
          fileId: file.id,
          oldParentId: args.oldParentId,
          newParentId: args.newParentId,
        })
      } catch (error) {
        console.error('moveDriveFiles failed.', {
          error,
        })
        ok = false
      }
    }
    /* eslint-enable no-restricted-syntax, no-await-in-loop */
    if (ok) {
      console.log('All selected files moved to', args.newParentId)
    }
    pending.value = false
  }

  async function trashDriveFiles(files) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    for (const file of files) {
      file.trashed = true
      updateFile(file)
    }
    for (const file of files) {
      await httpsCallable(functions, 'gdrive-trashDriveFile')({
        fileId: file.id,
        trashstate: true,
      })
    }
    pending.value = false
  }
  async function restoreDriveFiles(files) {
    pending.value = true
    /** Ask for consent if user does not have the proper scopes */
    await checkGDriveScope()
    for (const file of files) {
      file.trashed = false
      updateFile(file)
    }
    for (const file of files) {
      await httpsCallable(functions, 'gdrive-trashDriveFile')({
        fileId: file.id,
        trashstate: false,
      })
    }
    pending.value = false
  }

  return {
    driveFiles,
    folderCrumbs,
    pending,
    moveDriveFiles,
    trashDriveFiles,
    restoreDriveFiles,
    gotoDriveFolder,
    uploadFile,
    renameFile,
    shareFile,
    initDriveFolder,
    createNewFolder,
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useGoogleDriveStore, import.meta.webpackHot))
}

export default useGoogleDriveStore
