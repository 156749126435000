import { functions } from '@/firebase'
import { httpsCallable } from 'firebase/functions'
import { hasGrantedAllScopesGoogle, GoogleOAuthProvider } from 'google-oauth-gsi'

export const googleProvider = new GoogleOAuthProvider({
  clientId: '317545103947-pjnfcgth6qk6n0bfcm78tk1g6es7i12p.apps.googleusercontent.com',
  onScriptLoadError: () => console.log('google oauth gsi load error'),
  onScriptLoadSuccess: () => console.log('google oauth gsi loaded ok'),
})
export const hasValidToken = hasGrantedAllScopesGoogle

export function authenticateUserWithLoginForm(user, scope) {
  return new Promise((resolve, reject) => {
    const consent = googleProvider.useGoogleLogin({
      scope,
      flow: 'auth-code',
      onSuccess: async (res) => {
        try {
          const response = await httpsCallable(functions, 'auth-getOAuthToken')({
            code: res.code,
          })
          resolve(response.data)
        } catch (error) {
          console.error(error)
          reject(error)
        }
      },
    })
    consent()
  })
}

function pickerCallback(data, callback) {
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    const doc = data[google.picker.Response.DOCUMENTS][0]
    const filedata = {
      id: doc[google.picker.Document.ID],
      name: doc[google.picker.Document.NAME],
      url: doc[google.picker.Document.URL],
    }
    return callback(filedata)
  }
  return callback(null)
}

export async function openGooglePicker(user, company, callback) {
  const response = await authenticateUserWithLoginForm(user, 'https://www.googleapis.com/auth/drive.file')
  if (response.access_token) {
    const myview = new google.picker.DocsView(google.picker.ViewId.DOCS)
    myview.setMode(google.picker.DocsViewMode.LIST)
    myview.setEnableDrives(false)
    myview.setOwnedByMe(true)
    myview.setLabel('My Drive')
    myview.setMimeTypes('application/vnd.google-apps.presentation')
    const tfview = new google.picker.DocsView(google.picker.ViewId.DOCS)
    tfview.setMode(google.picker.DocsViewMode.LIST)
    tfview.setIncludeFolders(true)
    tfview.setParent(company.driveId)
    tfview.setLabel(company.name)
    tfview.setMimeTypes('application/vnd.google-apps.presentation')
    const picker = new google.picker.PickerBuilder()
      .addView(myview)
      .addView(tfview)
      .setTitle('Attach file')
      .setOAuthToken(response.access_token)
      .setCallback((data) => pickerCallback(data, callback))
      .build()
    picker.setVisible(true)
  }
}
