import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { collection, query, where } from 'firebase/firestore'
import { database } from '@/firebase'

export const useCompanyStore = defineStore('company', () => {
  const allCompanies = useCollection(query(collection(database, 'companies'), where('name', '!=', null)))
  return { allCompanies }
})

export default useCompanyStore
