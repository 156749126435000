import { defineStore } from 'pinia'
import { database } from '@/firebase'
import { doc } from 'firebase/firestore'
import { useDocument } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useZoomDataStore = defineStore('zoom-data', () => {
  const { user } = useUserStore()
  const zoomData = useDocument(doc(database, 'zoom', user.id))

  return { zoomData }
})

export default useZoomDataStore
