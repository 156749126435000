import { computed } from 'vue'
import { useCollection } from 'vuefire'
import { defineStore } from 'pinia'
import { query, where, collection, doc } from 'firebase/firestore'
import { useUserStore } from '@/users/stores'
import { database } from '@/firebase'

export const useDealsStore = defineStore('investorg', () => {
  const userStore = useUserStore()
  const user = computed(() => userStore.user)
  const investorgref = doc(database, 'investorgs', user.value.invorgid)
  const deals = useCollection(query(collection(database, 'applications'), where('investorg', '==', investorgref)))
  const dealsLoading = computed(() => deals.pending.value)
  return { deals, dealsLoading }
})

export default useDealsStore
