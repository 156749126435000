import { computed } from 'vue'
import { collection } from 'firebase/firestore'
import { database } from '@/firebase'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'

export const useNotesStore = defineStore('notes', () => {
  const { user } = useUserStore()
  const notes = useCollection(collection(database, 'companies', user.companyid, 'notes'))
  const notesLoading = computed(() => notes.pending.value)
  const userNotes = computed(() => (notesLoading.value
    ? [] : notes.value
      .filter((t) => t.userid === user.id)
      .sort((a, b) => (a.index && b.index ? a.index - b.index : a.created - b.created))))
  const companyNotes = computed(() => (notesLoading.value || !user.companyid
    ? [] : notes.value
      .filter((t) => t.companyid === user.companyid)
      .sort((a, b) => (a.index && b.index ? a.index - b.index : a.created - b.created))))
  const investorgNotes = computed(() => (notesLoading.value || !user.invorgid
    ? [] : notes.value
      .filter((note) => note.invorgid === user.invorgid)
      .sort((a, b) => a.created - b.created)))
  return { notes, notesLoading, userNotes, companyNotes, investorgNotes }
})

export default useNotesStore
