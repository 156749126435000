import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useCollection } from 'vuefire'
import { where, query, collectionGroup } from 'firebase/firestore'
import { database } from '@/firebase'
import { useUserStore } from '@/users/stores'

export const useInvestmentsStore = defineStore('investments', () => {
  const { user } = useUserStore()
  const userInvs = useCollection(query(
    collectionGroup(database, 'investments'),
    where('contactid', '==', user.contactid),
  ))
  const companyInvs = useCollection(query(
    collectionGroup(database, 'investments'),
    where('companyid', '==', user.companyid),
  ))
  const companyInvestments = computed(() => companyInvs.value || [] )//: companyInvs.value.sort((a, b) => a.date - b.date)))
  const investmentsLoading = computed(() => companyInvs.pending.value)
  const userInvestments = computed(() => userInvs.value || [])
  const userInvestmentsLoading = computed(() => userInvs.pending.value)
  return { companyInvestments, userInvestments, investmentsLoading, userInvestmentsLoading }
})

export default useInvestmentsStore
