import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, getDoc, doc, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyC5TzPgqhx8m_Y_MzRCOb3jwuhU0Eo0NVI',
  authDomain: 'entrepreneurssite.firebaseapp.com',
  databaseURL: 'https://entrepreneurssite-default-rtdb.firebaseio.com/',
  projectId: 'entrepreneurssite',
  storageBucket: 'entrepreneurssite.appspot.com',
  messagingSenderId: '317545103947',
  appId: '1:317545103947:web:085b106cdbc8ef0ebdc3ce',
  measurementId: 'G-LW7EP9G3XZ',
}
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const analytics = window.location.hostname !== 'localhost' ? getAnalytics(firebaseApp) : null
export const database = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
if (process.env.FIREBASE_FUNCTIONS === 'local') {
  // connectFirestoreEmulator(database, 'localhost', 7777)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}
// export const backupdb = getFirestore(firebaseApp, 'backup1')

export const storage = getStorage(firebaseApp)
export const auth = getAuth(firebaseApp)

/* eslint-disable no-param-reassign, no-restricted-syntax, no-await-in-loop */
export const getCurrentUser = async () => new Promise((resolve, reject) => {
  onAuthStateChanged(auth, (user) => {
    resolve(user)
  }, reject)
})

export const getUserProfile = async () => {
  const reference = await getCurrentUser()
  if (!reference) return null
  const docRef = await getDoc(doc(database, 'users', reference.uid))
  return { ...docRef.data(), id: docRef.id }
}