import { computed } from 'vue'
import { collection } from 'firebase/firestore'
import { database } from '@/firebase'
import { useCollection } from 'vuefire'
import { defineStore } from 'pinia'
import { companyContactTypes } from '@/utilities'
import { useUserStore, useUserCompanyStore } from '@/users/stores'

export const useContactsStore = defineStore('contacts', () => {
  const { user } = useUserStore()
  const companyStore = useUserCompanyStore()
  const company = computed(() => companyStore.company)
  const userContacts = useCollection(collection(database, 'users', user.id, 'user_contacts'))
  const userConnections = useCollection(collection(database, 'users', user.id, 'connections'))
  const companyContacts = useCollection(collection(database, 'companies', user.companyid, 'com_contacts'))
  const userContactsLoading = computed(() => userContacts.pending.value)
  const userConnectionsLoading = computed(() => userConnections.pending.value)
  const companyContactsLoading = computed(() => companyContacts.pending.value)

  const findContactTypesById = (contactId) => {
    const foundTypes = companyContactTypes
      .filter((t) => company.value?.[t.arrayname]?.includes(contactId))
      .map((t) => t.type)
    return foundTypes || null
  }

  const isMatchingContact = (existing, incoming) => (
    existing.firstName === incoming.firstName && existing.lastName === incoming.lastName
    // && (
    //   existing.email === incoming.email
    //     || existing.email2 === incoming.email
    //     || existing.phone === incoming.phone
    //     || existing.phone2 === incoming.phone2
    //     || existing.linkedin === incoming.linkedin
    // )
  )

  const flatCompanyContacts = computed(() => (
    companyContacts.value.map((cont) => {
      const flatcontact = { companycontactid: cont.id, ...cont, types: findContactTypesById(cont.id),
      }
      delete flatcontact.id
      if (flatcontact.contact) {
        Object.assign(flatcontact, flatcontact.contact)
        delete flatcontact.contact
      }
      return flatcontact
    })
  ))

  const flatUserContacts = computed(() => (
    userContacts.value.map((cont) => {
      const flatcontact = { usercontactid: cont.id, ...cont }
      delete flatcontact.id
      if (flatcontact.contact) {
        Object.assign(flatcontact, flatcontact.contact)
        delete flatcontact.contact
      }
      return flatcontact
    })
  ))

  const flatUserConnections = computed(() => (
    userConnections.value.map((cont) => {
      const flatcontact = { connectionid: cont.id, ...cont }
      delete flatcontact.id
      if (flatcontact.contact) {
        Object.assign(flatcontact, flatcontact.contact)
        delete flatcontact.contact
      }
      return flatcontact
    })
  ))

  const mergedContacts = computed(() => {
    // 3 sources of contacts : Google Contacts, Linkedin connections and Company Contacts
    const contacts = [...flatUserContacts.value]

    // Iterate over each user connection and compare against contacts
    flatUserConnections.value.forEach((userConnection) => {
      // Check if the connection matches any contact
      const existingIndex = contacts.findIndex((cont) => isMatchingContact(cont, userConnection))
      if (existingIndex > -1) { // If a matching contact is found, merge the contact/connection
        Object.assign(contacts[existingIndex], userConnection)
      } else { // If no matching contact is found, add the new format and add new contact
        contacts.push(userConnection)
      }
    })

    // Iterate over each company contact and compare against contacts
    flatCompanyContacts.value.forEach((companyContact) => {
      const existingIndex = contacts.findIndex((cont) => isMatchingContact(cont, companyContact))
      if (existingIndex > -1) { // If a matching contact is found, merge the contact
        Object.assign(contacts[existingIndex], companyContact)
      } else { // If no matching contact is found, add the contact
        contacts.push(companyContact)
      }
    })
    return contacts
  })

  return {
    userContacts,
    userConnections,
    companyContacts,
    mergedContacts,
    companyContactsLoading,
    userContactsLoading,
    userConnectionsLoading,
  }
})

export default useContactsStore
