<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
  <div id="edit-bar"></div>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { logEvent } from 'firebase/analytics'
import { analytics } from './firebase'

const route = useRoute()
watch(route, (newPageValue) => {
  if (!analytics) return
  logEvent(analytics, 'notification_received')
  logEvent(analytics, 'page_view', {
    page_title: newPageValue.name,
    page_path: newPageValue.fullPath,
  })
})

</script>
