import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useExtensionInfoStore = defineStore('extensioninfo', () => {
  const extensionInfo = ref(null)
  const cookies = ref(null)

  function setExtensionInfo(info) {
    extensionInfo.value = info
  }

  function setCookies(data) {
    cookies.value = data
  }

  return { extensionInfo, setExtensionInfo, cookies, setCookies }
})

export default useExtensionInfoStore
