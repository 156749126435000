import {computed, ref} from 'vue'
import { defineStore } from 'pinia'
import {query, where, collection, onSnapshot, doc, deleteDoc} from 'firebase/firestore'
import { database } from '@/firebase'
import { useCollection } from 'vuefire'
import { useUserStore } from '@/users/stores'
import { useTasksStore } from '@/tasks/stores'

export const useEmailsStore = defineStore('emails', () => {
  const { user } = useUserStore()
  const tasksStore = useTasksStore()
  const userTasks = computed(() => (tasksStore.userTasksPending ? [] : tasksStore.userTasks))
  const emailthreads = useCollection(query(
    collection(database, 'emailthreads'),
    where('companyid', '==', user.companyid),
  ))
  const emailthreadsLoading = computed(() => emailthreads.pending.value)
  const jobId = ref(null)
  const importing = ref(false)

  async function updateGmailTask() {
    const task = userTasks.value.find((t) => t.id === 'sync_gmail')
    if (task) deleteDoc(doc(database, 'companies', user.companyid, 'tasks', task.id))
  }

  function watchJob() {
    const unsub = onSnapshot(
      doc(database, 'users', user.id, 'tasks', jobId.value),
      (doc) => {
        const job = doc.data()
        if (['completed', 'error'].includes(job.status)) {
          importing.value = false
          jobId.value = null
          updateGmailTask()
          unsub()
        }
      },
    )
  }

  return { emailthreads, emailthreadsLoading, jobId, importing, watchJob }
})

export default useEmailsStore
