import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useCollection, useDocument } from 'vuefire'
import { collection, doc } from 'firebase/firestore'
import { database } from '@/firebase'

export const useInvestorsStore = defineStore('investors', () => {
  const investorgs = useCollection(collection(database, 'investorgs'))
  const crunchbaseReference = useDocument(doc(database, 'reference', 'crunchbase'))
  const invorgsLoading = computed(() => investorgs.pending.value)
  const columns = ref([])
  const keywords = ref(null)
  const pagenum = ref(1)
  const pagelength = ref(25)
  const sortby = ref([])
  const nameFilter = ref('')
  const typeFilter = ref([])
  const stageFilter = ref([])
  const showActive = ref(false)
  const showInTasks = ref(false)
  const keywordsFilter = ref(null)
  const focusFilter = ref([])
  const adminFilter = ref([])
  const regionFilter = ref([])
  const industryFilter = ref([])
  const minMembers = ref(0)
  const minInvest = ref(0)
  const maxDistance = ref(3000)
  const isSearching = ref(false)

  function resetTable() {
    pagenum.value = 1
    pagelength.value = 25
    sortby.value = []
  }

  function clearFilters() {
    nameFilter.value = ''
    typeFilter.value = []
    focusFilter.value = []
    regionFilter.value = []
    stageFilter.value = []
    adminFilter.value = []
    regionFilter.value = []
    industryFilter.value = []
    keywordsFilter.value = null
    keywords.value = null
    minMembers.value = 0
    minInvest.value = 0
    maxDistance.value = 3000
    showActive.value = false
    showInTasks.value = false
  }
  return {
    investorgs,
    crunchbaseReference,
    invorgsLoading,
    columns,
    keywords,
    pagenum,
    pagelength,
    sortby,
    nameFilter,
    typeFilter,
    stageFilter,
    showActive,
    showInTasks,
    keywordsFilter,
    focusFilter,
    adminFilter,
    regionFilter,
    industryFilter,
    minMembers,
    minInvest,
    maxDistance,
    resetTable,
    clearFilters,
    isSearching,
  }
})

export default useInvestorsStore
